import { Component, OnInit, Inject } from '@angular/core';
import { AgendaService } from '../_services/agenda.service';
import { PacienteService } from '../_services/paciente.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from '../_services/user.service';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UrlResolverService } from '../_services/urlResolver.service';
import { DialogSolicitarEstudioComponent } from '../dialog-solicitar-estudio/dialog-solicitar-estudio.component';

@Component({
  selector: 'app-dialog-select-cobertura',
  templateUrl: './dialog-select-cobertura.component.html',
  styleUrls: ['./dialog-select-cobertura.component.css'],
})

export class DialogSelectCoberturaComponent {
  paciente;
  coberturas;
  loadingCoberturas = true;
  selectCoberturaForm: UntypedFormGroup;
  crearCoberturaForm: UntypedFormGroup;
  modificarCoberturaForm: UntypedFormGroup;
  particular = false;
  protected onDestroyProperty = new Subject<void>();
  crearCobertura = false;
  loadingMutuales = false;
  mutuales = [];
  errorMutuales = false;
  errorCoberturas = false;
  camposVacios = true;
  mutualVacia = true;
  nroAfiliadoVacio = true;
  cargandoCobertura = false;
  editandoCobertura = false;
  loadingEditandoCobertura = false;
  campoEditarVacio = true;
  modificarCobertura = false;
  mutualAEditar;
  campoEditarMutualVacio = false;
  editoCampoAfiliado = false;
  loadingEliminarCoberturas = false;
  serviciosHabilitadosPedidos: string;
  errorNoMutuales = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogSelectCoberturaComponent>,
    private pacienteService: PacienteService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private matDialogModule: MatDialogModule,
    private urlResolverService: UrlResolverService,
    private agendaService: AgendaService,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
    this.paciente = this.data;
  }

  ngOnInit() {
    this.getMutualesPaciente();
    this.getserviciosHabilitadosPedidos();
  }

  servicioCargaPedidos() {
    return this.serviciosHabilitadosPedidos.match("@"+this.data.idServicio+"@") ? true : false;
  }

  getserviciosHabilitadosPedidos() {
    this.urlResolverService.getParamAppVariableConfig("SERVFACPED").toPromise().then(rp => {
      //console.log(rp)
      this.serviciosHabilitadosPedidos = rp;
    }).catch(error => {
      console.log(error)
    }).finally(() => { })
  }

  getMutualesPaciente() {
    let ultimaMutual;
    this.pacienteService.mutualesDePaciente(this.paciente.nroDocumento, this.paciente.tipoDocumento).subscribe((value) => {
      if (value.ok) {
        this.paciente.coberturas = value.paginador.elementos;
        if (this.paciente.coberturas.length != 1) {
        /*ultimaMutual = this.paciente.coberturas.reduce((prev, current) => {
          // Convertir las fechas de texto a objetos Date
          const fechaPrev = new Date(prev.fechaUltimoUso.split('/').reverse().join('-'));
          const fechaCurrent = new Date(current.fechaUltimoUso.split('/').reverse().join('-'));
          
          // Comparar las fechas y devolver la más reciente
          return fechaPrev > fechaCurrent ? prev : current;
        });
        console.log(ultimaMutual)
        ultimaMutual = ultimaMutual.mutual;*/
        ultimaMutual = this.paciente.coberturas[0].mutual;
        } else {
          ultimaMutual = this.paciente.coberturas[0].mutual;
        }
        this.particular = ultimaMutual == 14;
        //console.log(ultimaMutual);
        this.buildFiltroBusquedaForm(ultimaMutual);
      }
      else {
        this.errorNoMutuales = true;
        this.loadingCoberturas = false;
      }
        
    });
  }

  private buildFiltroBusquedaForm(ultimaCobertura) {
    this.selectCoberturaForm = this.formBuilder.group({
        cobertura: [ultimaCobertura],
      });
      this.loadingCoberturas = false;
      this.onChangeCobertura();
      
  }

  public cerrarPopup() {
    this.dialogRef.close();
  }

  protected onChangeCobertura() {
    this.selectCoberturaForm
      .get("cobertura")
      .valueChanges.pipe(takeUntil(this.onDestroyProperty))
      .subscribe((value) => {
        this.particular = value == 14;
      });  
  }

  protected onChangeMutual() {
    this.crearCoberturaForm
      .get("mutual")
      .valueChanges.pipe(takeUntil(this.onDestroyProperty))
      .subscribe((value) => {
        this.mutualVacia = false;
        this.camposVacios = this.mutualVacia || this.nroAfiliadoVacio;
      }); 

      this.crearCoberturaForm
      .get("nroAfiliado")
      .valueChanges.pipe(takeUntil(this.onDestroyProperty))
      .subscribe((value) => {
        if (value != '') {
          this.nroAfiliadoVacio = false;
          this.camposVacios = this.mutualVacia || this.nroAfiliadoVacio;
        } else {
          this.nroAfiliadoVacio = true;
        }
      }); 
  }

  openConfirmarTurno() {
    let mutualSeleccionada = this.paciente.coberturas.find(cobertura => cobertura.mutual === this.selectCoberturaForm.value["cobertura"])
    //console.log(mutualSeleccionada)
    this.paciente.idFinanciador = mutualSeleccionada.Financiador;
    this.paciente.mutual = mutualSeleccionada.mutual;
    this.paciente.nroCarnet = mutualSeleccionada.nroCarnet;
    //console.log(this.data);
    if (this.servicioCargaPedidos()) {
      //console.log(this.paciente);
      const dialogRef = this.dialog.open(DialogSolicitarEstudioComponent, {
        panelClass: 'app-full-bleed-dialog',
        width: '1300px',
        height: '700px',
        data: {
          patientData: this.paciente,
          servicio: {
            idServicio: this.data.idServicio,
            matriculaEfector: this.data.matriculaEfector
            // turno: this.data.turno
          }
        },
      }).afterClosed().subscribe(respuesta => {
        if (respuesta != undefined) {
          if (respuesta.ok) {
            this.paciente.numeroPedido = respuesta.numeroPedido;
            sessionStorage.setItem('p', JSON.stringify(this.paciente));
            this.dialogRef.close(respuesta);
          }
        }
      });
    } else {
      const dialogRef = this.dialog.open(DialogConfirmacionComponent, {
        data: `¿Está seguro que desea confirmar el turno? `,
      })
      .afterClosed()
        .subscribe(
          (confirmado: boolean) => {
            if (confirmado) {   
                let aux = {"ok":true} 
                 this.paciente.numeroPedido = 'null';
                 let result=JSON.stringify(this.paciente);
                 sessionStorage.setItem('p',result);
                 this.dialogRef.close(aux);             
            }
          }
         
        );
    }
    
  }

  mostrarMutuales() {
    this.loadingMutuales = true;
    this.crearCobertura = true;
    this.cargarMutuales();
  }

  cargarMutuales() {
    this.loadingMutuales = true;
    this.agendaService.obtenerMutuales().subscribe((x) => {
      this.mutuales = x.paginador.elementos;
      this.mutuales = this.mutuales.filter(mutual => !this.paciente.coberturas.some(p => p.mutual === parseInt(mutual.codigo))
      );
      this.mutuales.sort((a, b) => {
        if (a.nombre < b.nombre) {
          return -1;
        }
        if (a.nombre > b.nombre) {
          return 1;
        }
        return 0;
      })
      this.buildFormCargarCobertura();
    }, (error) => {
      console.log(error);
      this.errorMutuales = true;
      this.loadingMutuales = false;
    });
  }

  buildFormCargarCobertura() {
    this.crearCoberturaForm = this.formBuilder.group({
      mutual: null,
      nroAfiliado: null
    });
    this.nroAfiliadoVacio = true;
    this.mutualVacia = true;
    this.camposVacios = true;
    this.onChangeMutual();
    this.loadingMutuales = false;
  }

  async agregarCobertura() {
    this.cargandoCobertura = true;
    let datosPaciente = {
      nroDocumento: this.paciente.nroDocumento,
      tipoDocumento: this.paciente.tipoDocumento,
      nroCarnet: this.crearCoberturaForm.value["nroAfiliado"],
      idMutual: this.crearCoberturaForm.value["mutual"]
    }
    await this.pacienteService.setCoberturaPaciente(datosPaciente).subscribe(resul => {
      if (resul.ok) {
        const dialogRef = this.dialog.open(DialogMensajeComponent, {
             width: '500px',
             data: {
               ok: true,
               mensaje: 'Se agregó la cobertura correctamente',
             },
          }).afterClosed().subscribe(resul => {
            this.cargarMutuales();
            this.buildFormCargarCobertura();
            this.loadingCoberturas = true;
            
            this.getMutualesPaciente();
            
          })
          
      } else {
        const dialogRef = this.dialog.open(DialogMensajeComponent, {
          width: '500px',
          data: {
            ok: false,
            mensaje: 'Ocurrió un error al agregar la cobertura',
          },
       });
      }
      this.cargandoCobertura = false;
    });
  }

  dialogConfirmacionEliminarCobertura() {
    this.loadingEliminarCoberturas = true;
    let mutualAEliminar = this.paciente.coberturas.find(cobertura => cobertura.mutual === this.selectCoberturaForm.value["cobertura"])
    let datosPaciente = {
      nroDocumento: this.paciente.nroDocumento,
      tipoDocumento: this.paciente.tipoDocumento,
      nroCarnet: mutualAEliminar.nroCarnet,
      idMutual: mutualAEliminar.mutual,
      estado: 'INA',
    }
    
    const dialogRef = this.dialog.open(DialogConfirmacionComponent, {
      data: '¿Está seguro que desea eliminar la cobertura ' + mutualAEliminar.nombreMutual + '?',
    })
    .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.eliminarCobertura(datosPaciente);
        }
        else
          this.loadingCoberturas = false;
      });
  }

  async eliminarCobertura(datosPaciente: any) {
    await this.pacienteService.setEstadoCoberturaPaciente(datosPaciente).subscribe(resul => {
      if (resul.ok) {
        const dialogRef = this.dialog.open(DialogMensajeComponent, {
          width: '500px',
          data: {
            ok: true,
            mensaje: 'Se eliminó la cobertura correctamente',
          },
       }).afterClosed().subscribe(resul => {
          this.loadingCoberturas = true;
          this.getMutualesPaciente();
          this.cargarMutuales();
          this.buildFormCargarCobertura();
          this.loadingEliminarCoberturas = false;
       })
       
   } else {
     const dialogRef = this.dialog.open(DialogMensajeComponent, {
       width: '500px',
       data: {
         ok: false,
         mensaje: 'Ocurrió un error al eliminar la cobertura',
       },
    });
    }
      
    })
  }

  buildFormModificarCobertura() {
    this.modificarCoberturaForm = this.formBuilder.group({
      mutual: this.mutualAEditar.mutual,
      nroAfiliado: this.mutualAEditar.nroCarnet
    });
    this.onChangeModificarCobertura();
    this.loadingEditandoCobertura = false;
  }

  mostrarEditarCobertura() {
    this.loadingEditandoCobertura = true;
    this.mutualAEditar = this.paciente.coberturas.find(cobertura => cobertura.mutual === this.selectCoberturaForm.value["cobertura"])
    if (this.modificarCobertura) {
      this.campoEditarMutualVacio = true;
      this.editoCampoAfiliado = false;
      this.campoEditarVacio = true;
    }
    else 
      this.modificarCobertura = true;
    
    this.buildFormModificarCobertura();
    
  }

  dialogConfirmacionEditarCobertura() {
    
    const dialogRef = this.dialog.open(DialogConfirmacionComponent, {
      data: '¿Está seguro que desea modificar la cobertura ' + this.mutualAEditar.nombreMutual + '?',
    })
    .afterClosed()
      .subscribe(
        (confirmado: boolean) => {
          if (confirmado) {   
            this.editarCobertura();
          }
        })
  }

  async editarCobertura() {
    this.loadingEditandoCobertura = true;
    let datosPaciente = {
      nroDocumento: this.paciente.nroDocumento,
      tipoDocumento: this.paciente.tipoDocumento,
      nroCarnet: this.modificarCoberturaForm.value["nroAfiliado"],
      idMutual: this.modificarCoberturaForm.value["mutual"]
    }
    await this.pacienteService.setCoberturaPaciente(datosPaciente).subscribe(resul => {
      if (resul.ok) {
        const dialogRef = this.dialog.open(DialogMensajeComponent, {
          width: '500px',
          data: {
            ok: true,
            mensaje: 'Se modificó la cobertura correctamente',
          },
          }).afterClosed().subscribe(resul => {
            this.getMutualesPaciente(); 
            this.loadingEditandoCobertura = false;
          })
      } else {
          const dialogRef = this.dialog.open(DialogMensajeComponent, {
            width: '500px',
            data: {
              ok: false,
              mensaje: 'Ocurrió un error al modificar la cobertura',
            },
         });
      }
        this.loadingEditandoCobertura = false;
      })
  }

  protected onChangeModificarCobertura() {
    this.modificarCoberturaForm
      .get("nroAfiliado")
      .valueChanges.pipe(takeUntil(this.onDestroyProperty))
      .subscribe((value) => {
        this.editoCampoAfiliado = true;
        if (value != '') {
          this.campoEditarVacio = this.campoEditarMutualVacio;
        }
          
        else
          this.campoEditarVacio = true;
      }); 
      
      this.modificarCoberturaForm
      .get("mutual")
      .valueChanges.pipe(takeUntil(this.onDestroyProperty))
      .subscribe((value) => {
          this.campoEditarMutualVacio = false;
          if (this.editoCampoAfiliado)
            this.campoEditarVacio = this.modificarCoberturaForm.value["nroAfiliado"] === '';
      });

  }

}