import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Turno, TurnoAdapter } from '../_models/turno.model';
import { RespuestaTurno } from '../_models/respuestaTurno.model';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

const httpOptions = {
  headers: new HttpHeaders({}),
};

@Injectable({ providedIn: 'root' })
export class AgendaService {
  baseUrl: string;

  private currentPatientSubject: BehaviorSubject<any>;

  public currentPatient: Observable<any>;

  constructor(
    private http: HttpClient,
    private turnoAdapter: TurnoAdapter,
    private router: Router
  ) {
    this.baseUrl = AppComponent.baseUrl;
    this.currentPatientSubject = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('currentPatient'))
    );
    this.currentPatient = this.currentPatientSubject.asObservable();
  }

  getAgendaProfesional(matricula) {
    return this.http.get<any>(
      this.baseUrl +
        '/turnos/seguro/obtenerBloqueTurnosDeProfesional/' +
        matricula
    );
  }
  getProfesionalesSucursal(sucursal: number) {
    const datos: string = JSON.stringify({
      sucursal,
    });
    return this.http.post<any>(
      this.baseUrl + '/turnos/seguro/obtenerProfesionalesSucursal',
      datos,
      httpOptions
    );
  }

  getProfesional(matricula){    
    return this.http.get<any>(
      this.baseUrl + '/profesionales/seguro/recuperarDatosProfesional/'+ matricula);
  }
  


  getTurnosProfesional(dia, mes, año, hora, minutos, matricula) {
    // return this.http.get<any>(this.baseUrl + '/turnos/seguro/getTurnosProximaFecha/' + dia + '/' + mes +'/' + año + '/' + hora + '/' + minutos + '/' + matricula)
    return this.http.get<RespuestaTurno>(
      this.baseUrl +
        '/turnos/seguro/getTurnosProximaFecha/' +
        dia +
        '/' +
        mes +
        '/' +
        año +
        '/' +
        hora +
        '/' +
        minutos +
        '/' +
        matricula
    );
    // .pipe(
    //   // Adapt each item in the raw data array
    //   map((data: RespuestaTurno) =>
    //     data.turnos.map((item) => this.turnoAdapter.adapt(item))
    //   )
    // );
  }

  getProximoBloque(
    dia,
    mes,
    año,
    hora,
    minutos,
    matricula,
    sucursal,
    servicio
  ) {
    return this.http.get<any>(
      this.baseUrl +
        '/turnos/seguro/getProximoHorarioProfesional/' +
        dia +
        '/' +
        mes +
        '/' +
        año +
        '/' +
        hora +
        '/' +
        minutos +
        '/' +
        matricula +
        '/' +
        sucursal +
        '/' +
        servicio
    );
  }

  getAnteriorBloque(
    dia,
    mes,
    año,
    hora,
    minutos,
    matricula,
    sucursal,
    servicio
  ) {
    return this.http.get<any>(
      this.baseUrl +
        '/turnos/seguro/getAnteriorHorarioProfesional/' +
        dia +
        '/' +
        mes +
        '/' +
        año +
        '/' +
        hora +
        '/' +
        minutos +
        '/' +
        matricula +
        '/' +
        sucursal +
        '/' +
        servicio
    );
  }

  getBloqueTurnoLibre(
    dia,
    mes,
    año,
    hora,
    minutos,
    matricula,
    sucursal,
    servicio,
    diaVig,
    mesVig,
    añoVig
  ) {
    return this.http.get<any>(
      this.baseUrl +
        '/turnos/seguro/getProximoHorarioTurnoLibre/' +
        dia +
        '/' +
        mes +
        '/' +
        año +
        '/' +
        hora +
        '/' +
        minutos +
        '/' +
        matricula +
        '/' +
        sucursal +
        '/' +
        servicio +
        '/' +
        diaVig +
        '/' +
        mesVig +
        '/' +
        añoVig
    );
  }

  cancelarTurno(
    usuario,
    matricula,
    horaInicio,
    turno,
    dia,
    mes,
    año,
    horaTurnoTomado,
    numeroDoc,
    tipoDoc,
    sucursal
  ) {
    return this.http.get<any>(
      this.baseUrl +
        '/turnos/cancelarTurnoResevadoPorMedico/' +
        usuario +
        '/' +
        matricula +
        '/' +
        horaInicio +
        '/' +
        turno +
        '/' +
        dia +
        '/' +
        mes +
        '/' +
        año +
        '/' +
        horaTurnoTomado +
        '/' +
        numeroDoc +
        '/' +
        tipoDoc +
        '/' +
        sucursal
    );
  }

  llamarTv(
    consultorio,
    matricula,
    estadoTv,
    estadoTurno,
    horaInicio,
    fecha,
    ordenTurno,
    nombrePaciente,
    profesional,
    usuario,
    numeroDoc,
    tipoDoc,
    numPedido
  ) {
    let obj = {
      consultorio: consultorio,
      matricula: matricula,
      equipo: {},
      estado: estadoTv,
      estadoTurno: estadoTurno,
      fecha: fecha,
      numeroDocumento: numeroDoc,
      tipoDocumento: tipoDoc,
      numeroPedido: numPedido,
      orden: ordenTurno,
      desdeHora: horaInicio,
      paciente: nombrePaciente,
      profesional: profesional,
      usuario: usuario,
    };    

    return this.http.post<any>(
      this.baseUrl + '/turnos/seguro/llamartvconsultorios',
      obj
    );
  }

  tomarTurno(
    usuario,
    nroMatricula,
    turno,
    horaInicio,
    mutual,
    fechaSol,
    nroDoc,
    tipoDoc,
    desdeVigencia
  ) {
    return this.http.post<any>(
      this.baseUrl + '/turnos/tomarTurnoProfesional/',
      {
        usuario,
        nroMatricula,
        turno,
        horaInicio,
        mutual,
        fechaSol,
        nroDoc,
        tipoDoc,
        desdeVigencia,
      }
    );
  }

  liberarTurno(usuario, matricula, turno, horaInicio, dia, mes, año) {
    return this.http.get<any>(
      this.baseUrl +
        '/turnos/liberarTurnoProfesional/' +
        matricula +
        '/' +
        turno +
        '/' +
        usuario +
        '/' +
        horaInicio +
        '/' +
        dia +
        '/' +
        mes +
        '/' +
        año
    );
  }

  confirmarTurno(
    usuario,
    matricula,
    turno,
    horaInicio,
    mutual,
    dia,
    mes,
    anio,
    numeroDoc,
    tipoDoc,
    horaTurno,
    sucursal,
    numeroPedido,
  ) {
    const sectorTurnoId = 25
    numeroPedido = numeroPedido === null ? 'null' : numeroPedido
    return this.http.post<any>(
      this.baseUrl +
        '/turnos/confirmarTurnoProfesional',
        {
          usuario,         
          matricula,  
          turno,
          tipoDoc,
          numeroDoc, 
          mutual,
          horaInicio,
          dia,
          mes,
          anio,
          horaTurno,       
          sucursal,
          numeroPedido,
          sectorTurnoId,
      }
    );
  }

  registrarLLegadaPaciente(
    dia,
    mes,
    año,
    hora,
    minutos,
    orden,
    matricula,
    usuario
  ) {
    return this.http.get<any>(
      this.baseUrl +
        '/turnos/seguro/registrarLlegadaPaciente/' +
        dia +
        '/' +
        mes +
        '/' +
        año +
        '/' +
        hora +
        '/' +
        minutos +
        '/' +
        orden +
        '/' +
        matricula +
        '/' +
        usuario
    );
  }

  agendaTieneTurnos(agendaCancelar, totalOrdenes) {
    const datos: string = JSON.stringify({
      matricula: agendaCancelar.matricula,
      fecha: new DatePipe('en-US').transform(
        agendaCancelar.fecha,
        'MMM d, y 0:00:00 a'
      ),
      desdeHora: new DatePipe('en-US').transform(
        '01/01/1900 ' + agendaCancelar.desdeHora,
        'MMM d, y h:mm:ss a'
      ),
      desdeVigencia: new DatePipe('en-US').transform(
        agendaCancelar.desdeVigencia,
        'MMM d, y 0:00:00 a'
      ),
      ordenDesde: agendaCancelar.ordenDesde,
      ordenHasta:
        agendaCancelar.ordenHasta === totalOrdenes
          ? agendaCancelar.ordenHasta - 1
          : agendaCancelar.ordenHasta,
      motivo: agendaCancelar.motivo,
    });
    return this.http.post<any>(
      this.baseUrl + '/turnos/seguro/agendaTieneTurnos',
      datos
    );
  }
  cancelarHorario(agendaCancelar, totalOrdenes) {
    const datos: string = JSON.stringify({
      matricula: agendaCancelar.matricula,
      fecha: new DatePipe('en-US').transform(
        agendaCancelar.fecha,
        'MMM d, y 0:00:00 a'
      ),
      desdeHora: new DatePipe('en-US').transform(
        '01/01/1900 ' + agendaCancelar.desdeHora,
        'MMM d, y h:mm:ss a'
      ),
      desdeVigencia: new DatePipe('en-US').transform(
        agendaCancelar.desdeVigencia,
        'MMM d, y 0:00:00 a'
      ),
      ordenDesde: agendaCancelar.ordenDesde,
      ordenHasta:
        agendaCancelar.ordenHasta === totalOrdenes
          ? agendaCancelar.ordenHasta - 1
          : agendaCancelar.ordenHasta,
      motivo: agendaCancelar.motivo,
      sucursal: agendaCancelar.sucursal,
    });
    return this.http.post<any>(
      this.baseUrl + '/turnos/seguro/cancelarHorario',
      datos
    );
  }
  levantarCancelacionHorario(
    matricula,
    fecha,
    desdeHora,
    desdeVigencia,
    ordenDesde,
    ordenHasta
  ) {
    const datos: string = JSON.stringify({
      matricula,
      fecha: new DatePipe('en-US').transform(fecha, 'MMM d, y 0:00:00 a'),
      desdeHora: new DatePipe('en-US').transform(
        '01/01/1900 ' + desdeHora,
        'MMM d, y h:mm:ss a'
      ),
      desdeVigencia: new DatePipe('en-US').transform(
        desdeVigencia,
        'MMM d, y 0:00:00 a'
      ),
      ordenDesde,
      ordenHasta,
    });
    return this.http.post<any>(
      this.baseUrl + '/turnos/seguro/levantarCancelacionHorario',
      datos
    );
  }

  obtenerMutuales() {
    return this.http.get<any>(this.baseUrl + '/mutuales/obtenerMutuales');
  }
}
