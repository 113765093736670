<div class="d-flex justify-content-center" style="margin-top: 10%" *ngIf="loading">
  <mat-spinner [diameter]="60"></mat-spinner>
</div>



<div [hidden]="loading">

  <div class="row" style="margin-left: unset;">
    <p>Mostrar Historial Cl&iacute;nico dentro del per&iacute;odo:</p>
  </div>
  <div class="row" style="align-items: center; margin-left: unset;">
    <mat-form-field style="margin-right: 3%;">
      <input matInput [matDatepicker]="pickerDesde" placeholder="Fecha Desde" [max]="maxDate" [formControl]="dateDesde" (dateChange)="cambioFechaDesde($event)" 
        (dateInput)="cambioFechaDesde($event)">
      <mat-datepicker-toggle matSuffix [for]="pickerDesde"></mat-datepicker-toggle>
      <mat-datepicker #pickerDesde></mat-datepicker>
      <mat-error *ngIf="dateDesde.invalid">La fecha no es valida.</mat-error>
    </mat-form-field>

    <mat-form-field style="margin-right: 3%;">
      <input matInput [matDatepicker]="pickerHasta" placeholder="Fecha Hasta" [max]="maxDate" [formControl]="dateHasta" (dateChange)="cambioFechaHasta($event)" 
        (dateInput)="cambioFechaHasta($event)">
      <mat-datepicker-toggle matSuffix [for]="pickerHasta"></mat-datepicker-toggle>
      <mat-datepicker #pickerHasta></mat-datepicker>
      <mat-error *ngIf="dateHasta.invalid">La fecha no es valida.</mat-error>
    </mat-form-field>

    <button mat-raised-button color="primary" style="border-radius: 5px;width: 130px; margin-right: 3%;" (click)="filtrarPorFechas()" 
      [disabled]="dateDesde.invalid || dateHasta.invalid">
      <mat-icon>filter_list</mat-icon>  
      <span>Filtrar</span>
    </button>
    <!-- <mat-icon matTooltip="Seleccione las fechas para ver el Historial Clinico. Pueden existir episodios anteriores.">help_outline</mat-icon> -->
  </div>
  
  <div class="modal-content row" style="margin-left: unset; margin-top: 1%;">
    <div class="checkbox-wrapper">

      <div class="checkbox-column">
        <label><input class="caja" type="checkbox" [(ngModel)]="formValues.internacion" (click)="onSelectCheckbox()" name="internacion" required>  Internaciones</label>
        <label><input class="caja" type="checkbox" [(ngModel)]="formValues.turnos" (click)="onSelectCheckbox()" name="turnos">  Consultas</label>
        <label><input class="caja" type="checkbox" [(ngModel)]="formValues.consultasExternas" (click)="onSelectCheckbox()" name="consultasExternas"> Notas médica</label>
      </div>
  
      <div class="checkbox-column">
        <label> <input class="caja" type="checkbox" [(ngModel)]="formValues.ingresosGuardia" (click)="onSelectCheckbox()" name="ingresosGuardia">Ingresos a la guardia</label>
        <label> <input class="caja" type="checkbox" [(ngModel)]="formValues.pedidosDXI" (click)="onSelectCheckbox()" name="pedidosDXI">Estudios DXI</label>
        <label> <input class="caja" type="checkbox" [(ngModel)]="formValues.triages" (click)="onSelectCheckbox()" name="triages">Triages</label>
      </div>

      <div class="checkbox-column">
        <label> <input class="caja" type="checkbox" [(ngModel)]="formValues.registrosHCE" (click)="onSelectCheckbox()" name="registrosHCE">Registros HCE</label>
        <label> <input class="caja" type="checkbox" [(ngModel)]="formValues.fichasIdear" (click)="onSelectCheckbox()" name="fichasIdear">Fichas idear</label>
        <label> <input class="caja" type="checkbox" [(ngModel)]="formValues.todos" (click)="onSelectTodos()" name="todos">Todos</label>
      </div>
    </div>

    <div class="row" *ngIf="filtroVacio" style="margin-left: 2%; margin-top: 1%;">
      <p style="font-size: 15px; color: #fa0000;">*Debe seleccionar alguna de las opciones.</p>
    </div>
  </div>

  <div class="row" style="margin-left: unset;  margin-top: 1%;">
    <p style="font-size: 15px; color: #3f51b5;">Nota: considere que pueden existir episodios previos al per&iacute;odo seleccionado.</p>
  </div>

  <div id="filterModal" class="modal">
    
  </div>

  <div class="row" style="align-items: center; margin-left: unset;">
    <mat-form-field style="margin-right: 3%;">
      <mat-label>Buscar</mat-label>
      <input matInput (keyup)="applyFilter($event)" autocomplete="off" #input/>
    </mat-form-field>

    <button *ngIf="this.rolMHE" mat-raised-button color="primary" (click)="agregarPDF(this.currentPatient)" style="border-radius: 5px;width: 180px; ">
      <mat-icon>add</mat-icon>
      <span>Agregar documento</span>
    </button>
  </div>

  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
    <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef>Fecha</th>
        <td mat-cell *matCellDef="let element">
            {{ element.fechast ? element.fechast: element.fechaInicio }}
        </td>
    </ng-container>
    <ng-container matColumnDef="episodio">
        <th mat-header-cell *matHeaderCellDef>Episodio</th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.tipoEpisodio != 'Trasplante'">{{ element.tipoEpisodio }}</span>
            <span *ngIf="element.tipoEpisodio == 'Trasplante'">{{ element.descripcion }}</span> <!-- chequear como queda con el resto de las cosas. preguntar con barbi si esta bien o es un horror de frontend-->
            <span *ngIf="element.evolucion && element.evolucion.privada" style="color: red">*</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="profesional">
        <th mat-header-cell *matHeaderCellDef>Profesional</th>
        <td mat-cell *matCellDef="let element">
            {{ element.nombreProfesional ? (element.nombreProfesional | toTitleCase) : '-' }} {{ element.especialidadAtencion ? '('+ (element.especialidadAtencion | toTitleCase) + ')' : '' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef>Acciones</th>
      <td mat-cell *matCellDef="let element">
        <div class="row">
          <button color="primary" *ngIf="element.tipoEpisodio === 'Internacion'" (click)="irAInternacion(element)" mat-icon-button matTooltip="Ir a internaciones" >
            <mat-icon > remove_red_eye </mat-icon>
          </button>
          <button [disabled]="this.episodioVisualizado == element.idEpisodio" *ngIf="element.tipoEpisodio === 'Estudio' || element.tipoEpisodio === 'Registro paciente' || element.tipoEpisodio === 'Registros de medicos' " color="primary" (click)="openDialogEstudio(element)" matTooltip = "Ver documento" mat-icon-button>
            <mat-icon>picture_as_pdf</mat-icon>
          </button>
          <button *ngIf="element.hcdigital" color="primary" (click)="openDialog(element)" matTooltip="Ver documento" mat-icon-button>
            <mat-icon> picture_as_pdf </mat-icon>
          </button> 
          <button *ngIf="element.tipoEpisodio === 'Estudio' || element.tipoEpisodio === 'Registro paciente' || element.tipoEpisodio === 'Registros de medicos' || element.hcdigital " color="primary" (click)="abrirEnNuevaVentana(element)" matTooltip="Ver documento en nueva pestaña" mat-icon-button>
            <mat-icon>open_in_new</mat-icon>
          </button>
          <button  *ngIf="element.idTipoEpisodio == 44 && element.matriculaProfesional == this.matricula" color="warn" (click)="borrarDocumento(element)" matTooltip = "Borrar documento" mat-icon-button>
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button *ngIf="element.tipoEpisodio=='Trasplante'" (click)="imprimirTodoTrasplante(element.idEpisodio)" matTooltip="Imprimir episodio de trasplante">
            <mat-icon>picture_as_pdf</mat-icon>
          </button>
        </div>
        <div class="row" *ngIf="element.tipoEpisodio === 'Nota_Medica' && element.matriculaProfesional == this.matricula">
          <button matTooltip="Editar episodio" mat-icon-button (click)="editarEvolucion(element)">
            <mat-icon color="primary">edit</mat-icon>
          </button>
          <button  mat-icon-button matTooltip="Eliminar" (click)="borrarEvolucion(element)"> 
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <br>
    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay2.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'collapsed' : 'expanded'">
          <div class="example-element-description">

            <div *ngIf="element.cargandoEstudio" style="display: flex;flex-direction: row;">
              
              <div class="alert alert-primary" >
                <span>
                  <strong>Esta operación puede demorar, por favor, aguarde un momento.</strong>
                  
                </span>
              </div>

              <div style="padding-top:1% ;padding-left: 1% ;">
                <mat-spinner [diameter]="30"></mat-spinner>
              </div>
            </div>

            <div class="row">
              <div class="col-12" *ngIf="element.tipoEpisodio != 'Estudio' && element.observacion != '-'">
                <b>Observacion:</b>
                <p [innerHTML]="element.observacion"></p>
              </div>
              <div>
                <iframe [id]="element.idEpisodio" width="550px" height="400px" 
                  [hidden]="this.episodioVisualizado != element.idEpisodio || element.cargandoEstudio" >
                </iframe>
              </div>
              
              <div class="col-12">
                <div *ngFor="let e of element.estudiosPaciente">
                  {{ e.id }} - {{ e.nombreEstudio }}
                  <a *ngIf="e.estadoImgAurora == 0" target="_blank" href="{{ e.urlAurora }}">Codigo: {{e.codigoEstudio }}</a>
                 
                </div>
              </div>
            </div>
            <div class="row" *ngIf="element.tipoEpisodio == 'Trasplante' && element.subepisodios.length > 0">
              
              <div class="col-12">
                <b>Subepisodios:</b>
                <mat-card *ngFor="let subepi of element.subepisodios">
                  <mat-card-content>
                    <b>{{ subepi.descripcion | toTitleCase }}</b>
                    <div class="col-md-10 col-lg-10 col-xl-10" >
                      <span>Inicio: </span><b> {{ subepi.fechaInicio }}</b> 
                      <span>Profesional:</span> {{ subepi.nombreProfesional | toTitleCase }}
                      <p>
                        <b>{{ subepi.tipoDeEpisodio.descripcion }}</b>
                        <button *ngIf="subepi.tipoDeEpisodio.idTipoEpisodio==3"
                          matTooltip="Ir a la Internacion" (click)="irAInternaciones(subepi)"
                          color="primary" style="font-size:20px" class="mat-elevation-z1"
                          mat-raised-button [ngStyle]="{transform: 'scale(0.6)'}"
                        >
                          Ir a la internación
                        </button>
                        
                        <button 
                          matTooltip="Ver formularios"
                          (click)="verFormularios(subepi)"
                          color="primary"
                          style="font-size:20px"
                          mat-raised-button
                          [ngStyle]="{transform: 'scale(0.6)'}"
                        >
                          <mat-icon>visibility</mat-icon>
                          Ver Formularios
                        
                        </button>
                      </p>
                      
                    </div>
                  </mat-card-content>
                  
                </mat-card>
              </div>

            </div>
            <div class="row" *ngIf="element.tipoEpisodio == 'Trasplante' && element.subepisodios.length == 0">
              <div class="col-12">
                No hay subepisodios para mostrar.
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay" class="example-element-row"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
</div>